<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="itemsTipoCompCondIva"
        dense
        :loading="loading"
        class="elevation-1"
        :search="search"
        :sort-desc="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9">
                <v-card-title>
                  <span class="pl-1 primary--text">{{ title }}</span>
                </v-card-title>
              </v-col>
              <v-col cols="3" class="pt-6">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar tipo de comprobante</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteConfigTipoCompCondIva(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar tipo de comprobante</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="43%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditConfigTipoCompXCondIva
          :tipoCompCondicionIvaProp="tipoCompCondicionIvaProp"
          :procesoCobranzaConfigId="procesoCobranzaConfigId"
          :entFacId="entFacId"
          :tiposComprobantesProp="tiposComprobantesProp"
          @closeAndReload="closeAndReload"
        ></EditConfigTipoCompXCondIva>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfigTipoCompXCondIva from "@/components/modules/cuotas/configuracion/EditConfigTipoCompXCondIva.vue";

export default {
  components: { PageHeader, DeleteDialog, EditConfigTipoCompXCondIva },
  name: "ConfigTipoCompXCondIva",
  props: ["procesoCobranzaConfigId", "entFacId", "procesoCobranzaNom"],
  data() {
    return {
      title: `${enums.titles.TIPO_COMPROB_POR_COND_IVA}: ${this.procesoCobranzaNom}`,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      loading: false,
      search: "",
      showDeleteModal: false,
      idToDelete: null,
      titleDelete: "¿Eliminar comprobante?",
      itemsTipoCompCondIva: [],
      tiposComprobantesProp: [],
      tipoCompCondicionIvaProp: {},
      headers: [
        {
          text: "Condición IVA",
          value: "condIvaNom",
          sortable: true
        },
        {
          text: "Tipo de comprobante",
          value: "tCompNom",
          sortable: false
        },
        {
          text: "Sucursal",
          value: "sucursal",
          sortale: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      openModalEdit: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadConfigTipoCompXCondIva();
  },

  methods: {
    ...mapActions({
      getProcesosCobranzaConfigCondicionIva:
        "configuracion/getProcesosCobranzaConfigCondicionIva",
      deleteCondIvaProcesoCobranzaConfig:
        "configuracion/deleteCondIvaProcesoCobranzaConfig",
      getTiposComprobantesCancelatorios:
        "afiliaciones/getTiposComprobantesCancelatorios",
      setAlert: "user/setAlert"
    }),

    closeModal() {
      this.$emit("closeConfigTiposCompXCondicIva");
    },
    async loadConfigTipoCompXCondIva() {
      this.loading = true;
      const response = await this.getProcesosCobranzaConfigCondicionIva(
        this.procesoCobranzaConfigId
      );
      this.itemsTipoCompCondIva = response;
      this.loading = false;
      const tiposComprobante = await this.getTiposComprobantesCancelatorios(
        this.entFacId
      );
      this.tiposComprobantesProp = tiposComprobante;
    },
    deleteConfigTipoCompCondIva(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteCondIvaProcesoCobranzaConfig({
        procesoCobranzaCondIvaId: this.itemToDelete.procesoCobranzaCondIvaId
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConfigTipoCompXCondIva();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.tipoCompCondicionIvaProp = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.tipoCompCondicionIvaProp = {};
      this.loadConfigTipoCompXCondIva();
    }
  }
};
</script>

<style lang="scss" scoped></style>
