var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsTipoCompCondIva,"dense":"","loading":_vm.loading,"search":_vm.search,"sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-col',{staticClass:"pt-6",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar tipo de comprobante")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteConfigTipoCompCondIva(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar tipo de comprobante")])])]}}],null,true)}),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"43%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditConfigTipoCompXCondIva',{attrs:{"tipoCompCondicionIvaProp":_vm.tipoCompCondicionIvaProp,"procesoCobranzaConfigId":_vm.procesoCobranzaConfigId,"entFacId":_vm.entFacId,"tiposComprobantesProp":_vm.tiposComprobantesProp},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'25%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }