<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">{{ title }}</v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemsDatosEnvio"
        dense
        :loading="loading"
        class="elevation-1"
        :search="search"
        :sort-desc="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar configuración</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="40%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditConfigDatosEnvio
          :configDatosEnvioProp="configDatosEnvioProp"
          :procesoCobranzaConfigId="procesoCobranzaConfigId"
          :itemsDatosEnvio="itemsDatosEnvio"
          @closeAndReload="closeAndReload"
        ></EditConfigDatosEnvio>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :openDelete.sync="showDeleteModal"
        :maxWidth="'25%'"
        @onDeleteItem="confirmDelete()"
      />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfigDatosEnvio from "@/components/modules/cuotas/configuracion/EditConfigDatosEnvio.vue";

export default {
  components: { PageHeader, DeleteDialog, EditConfigDatosEnvio },
  name: "ConfigDatosDeEnvio",
  props: ["procesoCobranzaConfigId", "procesoCobranzaId", "procesoCobranzaNom"],
  data() {
    return {
      title: `${enums.titles.CONFIGURACION_DATOS_ENVIO}: ${this.procesoCobranzaNom}`,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      loading: false,
      titleDelete: "¿Eliminar configuración?",
      searchIcon: enums.icons.SEARCH,
      search: "",
      showDeleteModal: false,
      idToDelete: null,
      itemsDatosEnvio: [],
      configDatosEnvioProp: {},
      headers: [
        {
          text: "Valor",
          value: "dato"
        },
        {
          text: "Descripción",
          value: "tipoDatoDesc",
          sortable: false
        },

        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      openModalEdit: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadConfigDatosEnvio();
  },

  methods: {
    ...mapActions({
      getProcesosConfigDeta: "configuracion/getProcesosConfigDeta",
      deleteDetalleProcesoCobranzaConfig:
        "configuracion/deleteDetalleProcesoCobranzaConfig",
      setAlert: "user/setAlert"
    }),

    closeModal() {
      this.$emit("closeModalDeta");
    },
    async loadConfigDatosEnvio() {
      this.loading = true;
      const datosEnvio = {
        procesoCobranzaConfigId: this.procesoCobranzaConfigId,
        procesoCobranzaId: this.procesoCobranzaId
      };
      const response = await this.getProcesosConfigDeta(datosEnvio);
      this.itemsDatosEnvio = response.datosEnvio;
      this.loading = false;
      if (this.itemsDatosEnvio.length == 0) {
        this.setAlert({
          type: "info",
          message: "El proceso no tiene datos para configurar."
        });
      }
    },
    deleteConfigDatoEnvio(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteDetalleProcesoCobranzaConfig({
        idDeta: this.itemToDelete.idDeta
      });
      if (response.status === 200) {
        this.loadConfigDatosEnvio();
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.showDeleteModal = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.configDatosEnvioProp = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConfigDatosEnvio();
    }
  }
};
</script>

<style lang="scss" scoped></style>
