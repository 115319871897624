<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form3"
          form="form3"
          id="form3"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Dato -->
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Valor"
                class="theme--light.v-input input"
                v-model.trim="dato"
                :rules="[rules.requiredTrim(dato), rules.maxLength(dato, 200)]"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- descripcion -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="tipoDatoDescripcion"
                label="Descripción"
                dense
                auto-grow
                rows="1"
                outlined
                :disabled="!isNew"
                :filled="!isNew"
                :readonly="!isNew"
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(tipoDatoDescripcion),
                    rules.maxLength(tipoDatoDescripcion, 200)
                  ])
                "
                class="theme--light.v-input input"
                autocomplete="off"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || procesandoRequest"
          form="form3"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConfigDatosEnvio",
  directives: { mask },
  props: {
    configDatosEnvioProp: {
      type: Object
    },
    procesoCobranzaConfigId: {
      type: Number
    },
    itemsDatosEnvio: { type: Array }
  },
  data() {
    return {
      isFormValid: false,
      formEditTitle: enums.titles.EDITAR_CONFIG_DATOS_ENVIO,
      newTitle: enums.titles.NUEVA_CONFIG_DATOS_ENVIO,
      rules: rules,
      procesandoRequest: false,
      dato: "",
      tipoDato: null,
      isNew: false,
      tipoDatoDescripcion: null,
      tipoDato: null
    };
  },
  created() {
    if (this.configDatosEnvioProp) {
      this.setConfigDatosEnvio(this.configDatosEnvioProp);
    } else {
      this.isNew = true;
      this.newDatosEnvio();
    }
  },
  watch: {
    procesandoRequest() {
      if (this.procesandoRequest == true) {
        setTimeout(() => {
          this.procesandoRequest = false;
        }, 850);
      }
    }
  },
  methods: {
    ...mapActions({
      saveDetalleProcesoCobranzaConfig:
        "configuracion/saveDetalleProcesoCobranzaConfig",
      setAlert: "user/setAlert"
    }),
    setConfigDatosEnvio(obj) {
      this.dato = obj.dato;
      this.tipoDatoDescripcion = obj.tipoDatoDesc;
    },
    newDatosEnvio() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      this.procesandoRequest = true;
      const data = {
        procCobranzaConfigId: this.procesoCobranzaConfigId,
        idDeta: this.isNew ? null : this.configDatosEnvioProp.idDeta,
        dato: this.dato,
        tipoDato : this.configDatosEnvioProp.tipoDato,
        tipoDatoDesc: this.tipoDatoDescripcion
      };
      const res = await this.saveDetalleProcesoCobranzaConfig(data);
      this.addRegistrosNoConfigurados();
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    async addRegistrosNoConfigurados() {
      await Promise.all(
        this.itemsDatosEnvio.map(async item => {
          if (item.idDeta == null && item.tipoDatoDesc != this.tipoDatoDescripcion) {
            const itemAdicional = {
              procCobranzaConfigId: this.procesoCobranzaConfigId,
              idDeta: item.idDeta,
              tipoDato: item.tipoDato,
              dato: item.dato != null ? item.dato : "",
              tipoDatoDesc: item.tipoDatoDesc
            };
            await this.saveDetalleProcesoCobranzaConfig(itemAdicional);
          }
        })
      );
    }
  }
};
</script>

<style></style>
