<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pt-0 pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="procesosDeCobranza"
            class="elevation-1"
            :search="search"
            item-key="procesoCobranzaConfigId"
            :loading="isLoading"
            show-expand
            single-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalNewProcesoCobranza()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="configDatoProceso"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      openConfigDatosEnvio(
                        item.procesoCobranzaConfigId,
                        item.procesoCobranzaId,
                        item.procesoCobranzaNom
                      )
                    "
                  >
                    {{ settingIcon }}
                  </v-icon>
                </template>
                <span>Configuración de datos del proceso</span>
              </v-tooltip>
              <v-tooltip left max-width="25%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="configCondIva"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      openConfigTiposCompXCondicIva(
                        item.procesoCobranzaConfigId,
                        item.entFacId,
                        item.procesoCobranzaNom
                      )
                    "
                  >
                    {{ condicIvaIcon }}
                  </v-icon>
                </template>
                <span>
                  Tipo de comprobante por condición de IVA
                </span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canEdit"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar proceso</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canDelete"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteProceso(item.procesoCobranzaConfigId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar proceso</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left: 100px">Usuario alta:</strong>
                {{ item.usuarioAlta }}
                <strong style="padding-left: 100px">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left: 100px"
                  >Usuario modificación:</strong
                >
                {{ item.usuarioModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditProcesosCobranza
        :procesosCobranzaParams="procesosCobranzaParams"
        :comprobanteConfig="comprobanteConfig"
        @closeAndReload="closeAndReload"
      ></EditProcesosCobranza>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />

    <v-dialog
      v-if="openModalConfigDatosEnvio"
      v-model="openModalConfigDatosEnvio"
      @keydown.esc="closeModalDeta"
      max-width="1100px"
      persistent
    >
      <ConfigDatosDeEnvio
        :procesoCobranzaConfigId="procesoCobranzaConfigId"
        :procesoCobranzaNom="procesoCobranzaNom"
        :procesoCobranzaId="procesoCobranzaId"
        @closeModalDeta="closeModalDeta"
      ></ConfigDatosDeEnvio>
    </v-dialog>
    <v-dialog
      v-if="openModalConfigTipoXCondIva"
      v-model="openModalConfigTipoXCondIva"
      @keydown.esc="closeConfigTiposCompXCondicIva"
      max-width="1100px"
      persistent
    >
      <ConfigTipoCompXCondIva
        :procesoCobranzaConfigId="procesoCobranzaConfigId"
        :entFacId="entFacId"
        :procesoCobranzaNom="procesoCobranzaNom"
        @closeConfigTiposCompXCondicIva="closeConfigTiposCompXCondicIva"
      ></ConfigTipoCompXCondIva>
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditProcesosCobranza from "@/components/modules/cuotas/configuracion/EditProcesosCobranza.vue";
import ConfigDatosDeEnvio from "@/components/modules/cuotas/configuracion/ConfigDatosDeEnvio.vue";
import ConfigTipoCompXCondIva from "@/components/modules/cuotas/configuracion/ConfigTipoCompXCondIva.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditProcesosCobranza,
    ConfigDatosDeEnvio,
    ConfigTipoCompXCondIva,
    Ayuda
  },
  name: "ProcesosCobranza",
  data() {
    return {
      title: enums.titles.PROCESOS_COBRANZA,
      routeToGo: "FormasPagoYEntidadesCobranza",
      searchIcon: enums.icons.SEARCH,
      deleteIcon: enums.icons.DELETE,
      settingIcon: enums.icons.SETTINGS,
      condicIvaIcon: enums.icons.ICON_CONDICION_IVA,
      editIcon: enums.icons.EDIT,
      openModalConfigDatosEnvio: false,
      openModalConfigTipoXCondIva: false,
      canSeeDatosEnvio: false,
      comprobanteConfig: false,
      canSeeCompCondIva: false,
      procesoCobranzaId: null,
      entFacId: null,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      optionCode: enums.webSiteOptions.PROCESOS_COBRANZA,
      procesosDeCobranza: [],
      procesoCobranzaNom: null,
      procesosCobranzaParams: null,
      isLoading: false,
      search: "",
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      expanded: [],
      headers: [
        {
          text: "Proceso de cobranza",
          align: "start",
          value: "procesoCobranzaNom",
          sortable: false
        },
        {
          text: "Entidad facturante",
          value: "entFacNom",
          sortable: false
        },
        {
          text: "Entidad financiera",
          value: "entFinNom",
          sortable: false
        },

        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center"
        },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      allowedActions: null,
      openModalEdit: false,
      canCreate: false,
      canEdit: false,
      canDelete: false,
      configCondIva: false,
      configDatoProceso: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadProcesosCobranza();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },

  methods: {
    ...mapActions({
      getProcesosCobranzaConfig: "configuracion/getProcesosCobranzaConfig",
      deleteProcesoCobranzaConfig: "configuracion/deleteProcesoCobranzaConfig",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_PROCESO_COBRANZA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_PROCESO_COBRANZA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_PROCESO_COBRANZA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIG_CONDICION_IVA:
            this.configCondIva = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIG_DATOS_PROCESO:
            this.configDatoProceso = true;
            break;
          default:
            break;
        }
      });
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async loadProcesosCobranza() {
      const response = await this.getProcesosCobranzaConfig();
      this.procesosDeCobranza = response;
    },
    deleteProceso(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteProcesoCobranzaConfig({
        procesoCobranzaConfigId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadProcesosCobranza();
      }
    },

    openModal(item) {
      this.openModalEdit = true;
      this.procesosCobranzaParams = item;
      this.comprobanteConfig = item.hasComprobantesConfig;
    },
    openModalNewProcesoCobranza() {
      this.procesosCobranzaParams = null;
      this.comprobanteConfig = false;
      this.openModalEdit = true;
    },
    openConfigDatosEnvio(
      procesoCobranzaConfigId,
      procesoCobranzaId,
      procesoCobranzaNom
    ) {
      this.openModalConfigDatosEnvio = true;
      this.procesoCobranzaConfigId = procesoCobranzaConfigId;
      this.procesoCobranzaId = procesoCobranzaId;
      this.procesoCobranzaNom = procesoCobranzaNom;
    },
    closeModalDeta() {
      this.openModalConfigDatosEnvio = false;
      this.loadProcesosCobranza();
    },
    openConfigTiposCompXCondicIva(
      procesoCobranzaConfigId,
      entFacId,
      procesoCobranzaNom
    ) {
      this.openModalConfigTipoXCondIva = true;
      this.procesoCobranzaConfigId = procesoCobranzaConfigId;
      this.entFacId = entFacId;
      this.procesoCobranzaNom = procesoCobranzaNom;
    },
    closeConfigTiposCompXCondicIva() {
      this.openModalConfigTipoXCondIva = false;
      this.loadProcesosCobranza();
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadProcesosCobranza();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
