<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form3"
          form="form3"
          id="form3"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Condición IVA -->
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                :items="tiposIva"
                label="Condición de IVA"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de comprobante -->
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="tipoComprobanteSelected"
                :items="tiposComprobantesProp"
                label="Tipo de comprobante"
                item-text="value"
                item-value="id"
                return-object
                :rules="rules.required"
                outlined
                @change="
                  tipoComprobanteSelected !== null &&
                  tipoComprobanteSelected !== undefined
                    ? deshabilitarSucursal(tipoComprobanteSelected)
                    : (disabledSucursal = false)
                "
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Sucursal -->
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                v-model="sucursal"
                outlined
                dense
                :disabled="disabledSucursal"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Sucursal"
                v-mask="'####'"
                :rules="
                  sucursal === undefined
                    ? rules.required
                    : rules.positiveNumberHigher0
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || procesandoRequest"
          form="form3"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConfigTipoCompXCondIva",
  directives: { mask },
  props: {
    tipoCompCondicionIvaProp: {
      type: Object
    },
    procesoCobranzaConfigId: {
      type: Number
    },
    entFacId: {
      type: Number
    },
    tiposComprobantesProp: { type: Array }
  },
  data() {
    return {
      isFormValid: false,
      formEditTitle: enums.titles.EDITAR_CONFIG_TIPO_COMP_IVA,
      newTitle: enums.titles.NUEVA_CONFIG_TIPO_COMP_IVA,
      rules: rules,
      procesandoRequest: false,
      tipoIvaSelected: null,
      tiposIva: [],
      tipoComprobanteSelected: null,
      disabledSucursal: false,
      sucursal: null
    };
  },
  created() {
    this.setSelect();

    if (this.tipoCompCondicionIvaProp) {
      this.setConfigTipoCompXCondIva(this.tipoCompCondicionIvaProp);
    } else {
      this.isNew = true;
      this.newConfigCondicionIva();
    }
  },
  watch: {
    procesandoRequest() {
      if (this.procesandoRequest == true) {
        setTimeout(() => {
          this.procesandoRequest = false;
        }, 850);
      }
    }
  },
  methods: {
    ...mapActions({
      getTiposIva: "afiliaciones/getTiposIva",

      saveCondIvaProcesoCobranzaConfig:
        "configuracion/saveCondIvaProcesoCobranzaConfig",
      setAlert: "user/setAlert"
    }),
    newConfigCondicionIva() {
      this.formEditTitle = this.newTitle;
    },
    async setSelect() {
      const response = await this.getTiposIva();
      this.tiposIva = response;
    },
    setConfigTipoCompXCondIva(obj) {
      this.tipoIvaSelected = obj.condIvaId;
      this.tipoComprobanteSelected = obj.tCompId;
      let tipoComprobante = this.tiposComprobantesProp.find(
        x => x.id == this.tipoComprobanteSelected
      );
      this.deshabilitarSucursal(tipoComprobante);
      this.sucursal = obj.sucursal;
    },
    deshabilitarSucursal(obj) {
      if (obj.bool === true) {
        this.disabledSucursal = true;
        this.sucursal = 9999;
      } else {
        this.disabledSucursal = false;
        this.sucursal = undefined;
      }
    },

    async saveEdit() {
      this.procesandoRequest = true;
      const data = {
        procesoCobranzaConfigId: this.procesoCobranzaConfigId,
        procesoCobranzaCondIvaId: this.tipoCompCondicionIvaProp
          ? this.tipoCompCondicionIvaProp.procesoCobranzaCondIvaId
          : null,
        condIvaId: this.tipoIvaSelected,
        tCompId:
          typeof this.tipoComprobanteSelected === "object"
            ? this.tipoComprobanteSelected.id
            : this.tipoComprobanteSelected,
        sucursal: this.sucursal
      };
      const res = await this.saveCondIvaProcesoCobranzaConfig(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
