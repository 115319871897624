<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <!-- Procesos de cobranza-->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="procesosDeCobranzaSelected"
                :items="procesosDeCobranza"
                label="Proceso de cobranza"
                item-text="nombre"
                item-value="procesoCobranzaId"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Entidad facturante -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="entidadFacturanteSelected"
                :items="entFacturanteCombo"
                label="Entidad facturante"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                :disabled="
                  deshabilitarEntidadFacturante
                    ? entidadFacturanteSelected == true
                    : entidadFacturanteSelected == false
                "
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- Entidad financiera -->
          <div v-if="procesosDeCobranzaSelected != 1">
            <v-row>
              <v-col cols="12" sm="6" md="12" class="pt-0 py-0">
                <v-autocomplete
                  v-model="entidadFinancieraSelected"
                  :items="entFinancieraCombo"
                  label="Entidad financiera"
                  item-text="value"
                  item-value="id"
                  outlined
                  :rules="
                    entidadFinancieraSelected == 0
                      ? !rules.required
                      : rules.required
                  "
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn outlined @click="closeModal">Cancelar</v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || procesandoRequest"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  name: "EditProcesosCobranza",
  props: {
    procesosCobranzaParams: {
      type: Object,
      required: false,
      default: null
    },

    comprobanteConfig: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: vm => ({
    rules: rules,
    isFormValid: false,
    newTitle: enums.titles.NUEVO_PROCESO_COBRANZA,
    formEditTitle: enums.titles.EDITAR_PROCESO_COBRANZA,
    procesosDeCobranzaSelected: null,
    entidadFinancieraSelected: null,
    entidadFacturanteSelected: null,
    procesandoRequest: false,
    procesosDeCobranza: [],
    procesoCobranzaId: null,
    entFinancieraCombo: [],
    entFacturanteCombo: []
  }),
  created() {
    this.setSelect();

    if (this.procesosCobranzaParams != null) {
      this.setProcesosCobranza(this.procesosCobranzaParams);
    } else {
      this.newProcesoCobranza();
    }
  },
  watch: {
    procesandoRequest() {
      if (this.procesandoRequest == true) {
        setTimeout(() => {
          this.procesandoRequest = false;
        }, 850);
      }
    }
  },
  computed: {
    deshabilitarEntidadFacturante() {
      if (this.comprobanteConfig == true) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions({
      getProcesosCobranza: "configuracion/getProcesosCobranza",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      saveProcesoCobranzaConfig: "configuracion/saveProcesoCobranzaConfig",
      setAlert: "user/setAlert"
    }),
    async setSelect() {
      const procesos = await this.getProcesosCobranza();
      this.procesosDeCobranza = procesos.data;
      const entidadesFinancieras = await this.getEntidadesFinancieras();
      this.entFinancieraCombo = entidadesFinancieras;
      const formasPagos = await this.getEntidadesFacturantes();
      this.entFacturanteCombo = formasPagos;
    },

    async setProcesosCobranza(obj) {
      this.procesosDeCobranzaSelected = obj.procesoCobranzaId;

      this.entidadFinancieraSelected = obj.entFinId;
      this.entidadFacturanteSelected = obj.entFacId;
    },

    async newProcesoCobranza() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      this.procesandoRequest = true;
      const data = {
        procesoCobranzaConfigId: this.procesosCobranzaParams
          ? this.procesosCobranzaParams.procesoCobranzaConfigId
          : null,
        procesoCobranzaId: this.procesosDeCobranzaSelected,
        entFinId:
          this.procesosDeCobranzaSelected == 1
            ? null
            : parseInt(this.entidadFinancieraSelected),
        entFacId: parseInt(this.entidadFacturanteSelected)
      };
      const res = await this.saveProcesoCobranzaConfig(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
